<template>
  <b-modal
    id="modal-merchant-user-update"
    ref="modal"
    v-model="open"
    size="lg"
    no-close-on-backdrop
    hide-footer
    title="UPDATE MERCHANT USER"
  >
    <b-overlay
      :show="modalLoading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <ValidationObserver
        ref="updateMerchantUserForm"
        v-slot="{ handleSubmit }"
        slim
      >
        <b-form
          class="my-8"
          @submit.prevent="handleSubmit(onClickUpdateMerchantUser)"
        >
          <b-row>
            <b-col cols="6">
              <b-form-group
                label="Username"
                label-for="h-username"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="username"
                  rules="required"
                >
                  <b-form-input
                    id="username"
                    v-model="form.username"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter the username"
                    type="text"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                label="User Role"
                label-for="h-userRole"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="userRole"
                  rules="required"
                >
                  <v-select
                    v-model="form.userRole"
                    :options="merchantRoleOptions"
                    :reduce="option => option.id"
                    label="name"
                    autocomplete="nope"
                    placeholder="Select the user role"
                  >
                    <template v-slot:option="option">
                      {{ option.name }}
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group
                label="First Name"
                label-for="h-firstName"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="firstName"
                  rules="required"
                >
                  <b-form-input
                    id="firstName"
                    v-model="form.firstName"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter the first name"
                    type="text"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                label="Last Name"
                label-for="h-lastName"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="lastName"
                  rules="required"
                >
                  <b-form-input
                    id="lastName"
                    v-model="form.lastName"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter the last name"
                    type="text"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group
                label="Email"
                label-for="h-userEmail"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="userEmail"
                  rules="required"
                >
                  <b-form-input
                    id="userEmail"
                    v-model="form.userEmail"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter the admin email"
                    type="email"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                label="Contact No."
                label-for="h-userContactNo"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="userContactNo"
                  rules="required"
                >
                  <b-form-input
                    id="userContactNo"
                    v-model="form.userContactNo"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter the contactNo"
                    type="text"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="addingThroughUserRoute"
              cols="6"
            >
              <b-form-group
                label="Business Profile*"
                label-for="h-merchantBusiness"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="h-c-merchantBusiness"
                  vid="h-c-merchantBusiness"
                  rules="required"
                >
                  <v-select
                    v-model="merchant_business_id"
                    :options="businesses"
                    :reduce="option => option.id"
                    label="business_name"
                    autocomplete="nope"
                    placeholder="Select Business profile"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group
                label="Status"
                label-for="h-status"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="status"
                  rules=""
                >
                  <b-form-group>
                    <b-form-checkbox
                      id="status"
                      v-model="form.is_active"
                    >
                      Active
                    </b-form-checkbox>
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- submit and reset -->
          <b-row>
            <b-col
              offset-md="4"
            >
              <div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  class="mr-1 custom-button-color"
                >
                  Submit
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                >
                  Clear
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BButton,
  BModal,
  BCol,
  BFormGroup,
  BForm,
  BFormInput,
  BFormCheckbox,
  BRow,
  BOverlay,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import vSelect from 'vue-select'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const MerchantRepository = RepositoryFactory.get('merchant')
const MerchantRoleRepository = RepositoryFactory.get('role')
const ResourceRepository = RepositoryFactory.get('resource')

function initialState() {
  return {
    open: false,
    id: null,
    form: {},
    modalLoading: false,
    loading: false,
    merchantRoleOptions: [],
    merchant_business_id: null,
    addingThroughUserRoute: false,
  }
}

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BButton,
    BModal,
    BCol,
    BFormGroup,
    BForm,
    BFormInput,
    BFormCheckbox,
    BRow,
    BOverlay,
    vSelect,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  methods: {
    async openModal(businessId, userId) {
      Object.assign(this.$data, initialState())
      this.open = true
      this.addingThroughUserRoute = window.location.href.includes('/users')
      this.merchant_business_id = businessId
      this.userId = userId
      this.modalLoading = true
      await this.getBusinessList()
      await this.fetchMerchantRoleList()
      await this.fetchMerchantUserDetails()
      this.modalLoading = false
    },
    async fetchMerchantRoleList() {
      try {
        this.loading = true
        const { data } = (await MerchantRoleRepository.getRoleListforDropdown()).data
        this.merchantRoleOptions = data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async fetchMerchantUserDetails() {
      try {
        const { data } = (await MerchantRepository.getMerchantUserDetails(this.userId)).data
        this.form = {
          firstName: data.first_name,
          lastName: data.last_name,
          userEmail: data.email,
          username: data.user_name,
          userContactNo: data.phone,
          userRole: data.role.id,
          is_active: data.is_active,
        }
        this.merchant_business_id = data.merchant_business[0].id
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async getBusinessList() {
      try {
        const { data } = (await ResourceRepository.getBusinessList()).data
        this.businesses = data
        if (this.businesses.length === 1) {
          this.merchant_business_id = this.businesses[0].id
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async onClickUpdateMerchantUser() {
      this.modalLoading = true
      try {
        const payload = {
          merchant_business_id: this.merchant_business_id,
          role_id: this.form.userRole,
          user_details: {
            first_name: this.form.firstName,
            last_name: this.form.lastName,
            email: this.form.userEmail,
            user_name: this.form.username,
            phone: this.form.userContactNo,
            is_active: this.form.is_active,
          },
        }

        await MerchantRepository.updateMerchantUser(this.userId, payload)
        this.showSuccessMessage('Merchant user updated successfully')
        this.open = false
        this.$parent.onClickRefresh()
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.modalLoading = false
    },
  },
}
</script>
<style scoped>
@import "../../../node_modules/@syncfusion/ej2-base/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-vue-navigations/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-inputs/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-buttons/styles/fabric.css";
@import '../../@core/scss/button-color.scss';
</style>
