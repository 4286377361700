<template>
  <div>
    <div>
      <b-card-actions
        title="Filters"
        action-collapse
        collapsed
      >
        <b-row>
          <b-col
            cols="6"
            md="3"
          >
            <b-form-group
              label="User Role"
              label-for="h-userRole"
            >
              <v-select
                v-model="filters['role_id']"
                :options="merchantRoleOptions"
                :reduce="option => option.id"
                label="name"
                placeholder="Select User Role"
                autocomplete="nope"
              >
                <template v-slot:option="option">
                  {{ option.name }}
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col
            cols="6"
            lg="3"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="filters['email_search']"
                placeholder="Search"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="6"
            lg="3"
          >
            <b-form-group
              label="Contact Number "
              label-for="phone"
            >
              <b-form-input
                id="phone"
                v-model="filters['phone']"
                placeholder="Search"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Apply and Clear Filters -->
        <b-row class="mt-1">
          <b-col>
            <filter-buttons
              :filter-data="filters"
              :apply-filters="applyFilters"
              :clear-filters="clearFilters"
            />
          </b-col>
        </b-row>
      </b-card-actions>
    </div>
    <div>
      <b-card
        no-body
      >
        <div>
          <div
            class="m-2"
          >
            <!-- input -->
            <b-row>
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  class="custom-button-color"
                  @click="onClickOpenAddMerchantUserModal"
                >
                  <feather-icon icon="PlusIcon" />
                  Add New
                </b-button>
              </b-col>
            </b-row>
          </div>

          <!-- table -->
          <b-overlay
            id="overlay-background"
            variant="light"
            opacity="0.30"
            blur="10px"
            rounded="sm"
          >
            <b-table
              striped
              hover
              responsive
              :per-page="perPage"
              :items="rows"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              show-empty
              sticky-header="100vh"
              class="hide-vertical-scroll"
            >
              <template #empty>
                <TableDataFetching
                  :rows="rows"
                  :data-loading="dataLoading"
                />
              </template>
              <template #cell(name)="data">
                <div class="d-flex align-items-center">
                  <b-avatar
                    size="32"
                    :text="avatarText(data.item.name)"
                    variant="light-primary"
                  />
                  <div>
                    <div class="font-weight-bolder ml-1">
                      {{ data.item.name }}
                    </div>
                  </div>
                </div>
              </template>
              <template #cell(roles)="data">
                {{ data.value }}
              </template>
              <template #cell(created_at)="data">
                <div class="d-flex align-items-center">
                  <div>
                    <div class="font-weight-bolder ml-1">
                      {{ timestampToDateTime(data.item.created_at) }}
                    </div>
                  </div>
                </div>
              </template>

              <template #cell(is_active)="data">
                {{ data.value }}
                <b-badge :variant="status[1][1]">
                  {{ status[0][1] }}
                </b-badge>
              </template>

              <template #cell(action)="data">
                {{ data.value }}
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="12"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item
                    @click="onClickOpenManageUserModal(data.item.id)"
                  >
                    <feather-icon icon="ToolIcon" />
                    Manage User
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-can="'reset-password-merchant-users'"
                    @click="onClickResetPassword(data.item)"
                  >
                    <feather-icon icon="LockIcon" />
                    Reset User Password
                  </b-dropdown-item>
                <!--                <b-dropdown-item-->
                <!--                  @click="onClickOpenDeleteUserConfirm(data.item.id)"-->
                <!--                >-->
                <!--                  <feather-icon icon="ToolIcon" />-->
                <!--                  Delete User-->
                <!--                </b-dropdown-item>-->
                </b-dropdown>
              </template>
            </b-table>
            <div class="mx-2 mb-2">
              <b-row>
                <b-col
                  class="d-flex align-items-center justify-content-sm-start"
                  md="3"
                >
                  <label>Per page</label>
                  <v-select
                    v-model="perPage"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="pageOptions"
                    :clearable="false"
                    class="per-page-selector d-inline-block ml-1 width-100 mr-1"
                  />
                </b-col>
                <b-col
                  md="9"
                  class="d-flex align-items-center justify-content-sm-end"
                >
                  <span class="text-muted mr-1">Showing {{ meta.from }} to {{ meta.to }} of {{ meta.total }} entries</span>
                  <!-- Pagination -->
                  <b-pagination
                    v-model="page"
                    :per-page="perPage"
                    :total-rows="(meta.total)? meta.total : 0"
                    class="mb-0 mt-1 mt-sm-0"
                    first-number
                    last-number
                    prev-class="prev-item"
                    next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </div>
          </b-overlay>
        </div>
      </b-card>

      <create-merchant-user-modal ref="add_new_merchant_user_modal" />
      <update-merchant-user-modal ref="update_merchant_user_modal" />
      <reset-password-merchant-user-modal ref="resetPasswordMerchantUserModal" />
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BPagination,
  BRow,
  BOverlay,
  VBModal,
  BTable,
  BDropdown,
  BDropdownItem,
  BBadge,
  BAvatar,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import ErrorMessage from '@core/mixins/ErrorMessage'
import Filter from '@core/mixins/filter'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import { timestampToDateTime } from '@core/utils/timeUtil'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import store from '@/store'
import CreateMerchantUserModal from '@/components/merchant/CreateMerchantUserModal.vue'
import UpdateMerchantUserModal from '@/components/merchant/UpdateMerchantUserModal.vue'
import ResetPasswordMerchantUserModal from '@/components/merchant/ResetPasswordMerchantUserModal.vue'
import FilterButtons from '@/components/Filter/FilterButtons.vue'

const MerchantRepository = RepositoryFactory.get('merchant')
const MerchantRoleRepository = RepositoryFactory.get('role')

function initialState() {
  return {
    avatarText,
    timestampToDateTime,
    filters: {},
    total: 0,
    meta: {},
    dataLoading: false,
    sort: '',
    perPage: 10,
    pageOptions: [10, 20, 50],
    totalRows: 1,
    page: 1,
    sortBy: '',
    sortDesc: false,
    sortDirection: 'asc',
    userRole: [],
    filter: null,
    filterOn: [],
    merchantRoleOptions: [],
    fields: [
      {
        key: 'id', label: 'Ref No', sortable: true, thClass: 'd-none', tdClass: 'd-none',
      },
      {
        key: 'first_name', label: 'First Name', sortable: true, thClass: 'text-left', tdClass: 'text-left',
      },
      {
        key: 'last_name', label: 'Last Name', sortable: false, thClass: 'text-left', tdClass: 'text-left',
      },
      {
        key: 'email', label: 'Email', sortable: false, thClass: 'text-left', tdClass: 'text-left',
      },
      {
        key: 'phone', label: 'Contact Number', sortable: false, thClass: 'text-left', tdClass: 'text-left',
      },
      {
        key: 'role_name', label: 'User Role', sortable: false, thClass: 'text-left', tdClass: 'text-left',
      },
      {

        key: 'created_at', label: 'Created At', sortable: true, thClass: 'text-center', tdClass: 'text-center',
      },
      {
        key: 'action', label: 'Action', sortable: false, thClass: 'text-center', tdClass: 'text-center',
      },
    ],
    rows: [],
    business_id: null,
  }
}

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BPagination,
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BOverlay,
    vSelect,
    BDropdown,
    BDropdownItem,
    BBadge,
    BAvatar,
    BFormGroup,
    BFormInput,
    BCardActions,
    CreateMerchantUserModal,
    UpdateMerchantUserModal,
    ResetPasswordMerchantUserModal,
    FilterButtons,
  },
  mixins: [Filter, SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {
    options: {
      handler() {
        this.fetchMerchantUserList()
      },
    },
    page() {
      this.fetchMerchantUserList()
    },
    perPage() {
      this.fetchMerchantUserList()
    },
  },
  mounted() {
    // eslint-disable-next-line prefer-destructuring
    if (window.location.href.includes('businesses')) this.business_id = window.location.href.split('/').reverse()[0]
    this.fetchMerchantUserList()
    this.fetchMerchantRoleList()
    // eslint-disable-next-line prefer-destructuring
  },
  methods: {
    async fetchMerchantRoleList() {
      try {
        const { data } = (await MerchantRoleRepository.getRoleListforDropdown()).data
        this.merchantRoleOptions = data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    onClickRefresh() {
      this.fetchMerchantUserList()
    },
    onClickOpenAddMerchantUserModal() {
      this.$refs.add_new_merchant_user_modal.openModal(this.$route.params.businessId)
    },
    onClickOpenManageUserModal(id) {
      this.$refs.update_merchant_user_modal.openModal(this.$route.params.businessId, id)
    },
    onClickResetPassword(data) {
      this.$refs.resetPasswordMerchantUserModal.openModal(data)
    },
    // onClickOpenDeleteUserConfirm() {
    //   // Set Merchant User Delete Confirm Modal
    // },
    async fetchMerchantUserList() {
      this.dataLoading = true
      this.rows = []
      try {
        const { data } = (await MerchantRepository.getMerchantUserListWithFilters(this.business_id, this.page, this.filterQuery, this.perPage, this.sort))
        this.rows = data.data
        this.meta = data.meta
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.dataLoading = false
    },
    handleChangePage(page) {
      this.page = page
      this.fetchMerchantUserList()
    },
    applyFilters() {
      this.fetchMerchantUserList()
    },
    clearFilters() {
      this.filters = {}
      this.$nextTick(() => {
        this.fetchMerchantUserList()
      })
    },
    changePage(value) {
      this.perPage = value
    },
    // REQUIRED
  },
}
</script>
<style lang="scss">
@import '../@core/scss/vue/libs/vue-select.scss';
@import '../../@core/scss/button-color.scss';
</style>
