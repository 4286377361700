<template>
  <b-modal
    id="modal-staff-add"
    ref="modal"
    v-model="open"
    hide-footer
    no-close-on-backdrop
    size="lg"
    title="MERCHANT USER PASSWORD RESET"
  >
    <b-overlay
      :show="modalLoading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <ValidationObserver
        ref="createStaffForm"
        v-slot="{ }"
        slim
      >
        <b-form
          class="my-8"
        >
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="First Name"
                label-cols-md="3"
                label-for="h-fName"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="First Name"
                  rules="required"
                >
                  <b-form-input
                    id="fname"
                    v-model="form.first_name"
                    :state="errors.length > 0 ? false:null"
                    disabled
                    placeholder="First Name"
                    type="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Last Name"
                label-cols-md="3"
                label-for="h-lName"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="Last Name"
                  rules=""
                >
                  <b-form-input
                    id="lnames"
                    v-model="form.last_name"
                    disabled
                    :state="errors.length > 0 ? false:null"
                    placeholder="First Name"
                    type="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Password"
                label-cols-md="3"
                label-for="password"
              >
                <b-input-group>
                  <b-form-input
                    id="password"
                    v-model="new_password"
                    placeholder="Enter new password"
                    :type="showPassword ? 'text' : 'password'"
                  />
                  <b-input-group-append>
                    <b-button
                      v-b-tooltip.hover.right="'Show password'"
                      variant="outline-primary"
                      @click="togglePasswordVisibility"
                    >
                      <feather-icon :icon="showPassword ? 'EyeOffIcon' : 'EyeIcon'" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-2 mb-1">
            <b-col
              offset-md="12"
            >
              <div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="mr-0 ml-auto float-right custom-button-color"
                  @click="onClickResetPassword"
                >
                  Save & Close
                </b-button>
              </div>
            </b-col>
          </b-row>

        </b-form>
      </ValidationObserver>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BButton,
  BModal,
  BCol,
  BFormGroup,
  BForm,
  BFormInput,
  BRow,
  BOverlay,
  BInputGroup,
  BInputGroupAppend,
  VBTooltip,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const MerchantRepository = RepositoryFactory.get('merchant')

function initialState() {
  return {
    open: false,
    id: null,
    data: {},
    form: {},
    modalLoading: false,
    loading: false,
    new_password: '',
    showPassword: false,
  }
}

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    BButton,
    BModal,
    BCol,
    BFormGroup,
    BForm,
    BFormInput,
    BRow,
    BOverlay,
    BInputGroup,
    BInputGroupAppend,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  methods: {
    async openModal(data) {
      await Object.assign(this.$data, initialState())
      this.open = true
      this.form = data
    },
    async onClickResetPassword() {
      this.modalLoading = true
      try {
        const payload = {
          password: this.new_password,
        }
        const res = await MerchantRepository.merchantUserResetPassword(this.form.id, payload)
        if (res.status === 200) {
          this.showSuccessMessage('Merchant User Password Reset Successfully')
          this.open = false
        } else {
          this.showSuccessMessage('Failed To Reset Merchant User Password')
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.modalLoading = false
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword
    },
  },
}
</script>
<style lang="scss">
@import '../../@core/scss/button-color.scss';
</style>
