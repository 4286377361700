import { render, staticRenderFns } from "./UpdateMerchantUserModal.vue?vue&type=template&id=64f9d076&scoped=true&"
import script from "./UpdateMerchantUserModal.vue?vue&type=script&lang=js&"
export * from "./UpdateMerchantUserModal.vue?vue&type=script&lang=js&"
import style0 from "./UpdateMerchantUserModal.vue?vue&type=style&index=0&id=64f9d076&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64f9d076",
  null
  
)

export default component.exports